import React, { Suspense, createContext } from 'react'
import { HashRouter, Route, Routes } from 'react-router-dom'
import './scss/style.scss'
import { KeycloakProvider, useKeycloak } from 'keycloak-react-web'
import Keycloak from 'keycloak-js'

const UserContext = createContext(null)

const keycloakSetting = {
  url: process.env.REACT_APP_KEYCLOAK_URL,
  realm: process.env.REACT_APP_KEYCLOAK_REALM,
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
}

const authInstance = new Keycloak(keycloakSetting)

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Page404 = React.lazy(() => import('./views/pages/errors/Page404'))
const Page500 = React.lazy(() => import('./views/pages/errors/Page500'))

const AppContent = () => {
  const { initialized, keycloak } = useKeycloak()

  if (!initialized) {
    return loading
  }

  if (!keycloak.authenticated) {
    keycloak.login()
    return loading
  }

  keycloak
    .updateToken(30)
    .then((refreshed) => {
      if (refreshed) {
        console.log('Token was successfully refreshed')
      } else {
        console.log('Token is still valid')
      }
    })
    .catch(() => {
      console.log('Failed to refresh the token, or the session has expired')
    })

  return (
    <UserContext.Provider value={keycloak}>
      <HashRouter>
        <Suspense fallback={loading}>
          <Routes>
            <Route exact path="/404" name="Page 404" element={<Page404 />} />
            <Route exact path="/500" name="Page 500" element={<Page500 />} />
            <Route path="*" name="Home" element={<DefaultLayout />} />
          </Routes>
        </Suspense>
      </HashRouter>
    </UserContext.Provider>
  )
}

const App = () => {
  return (
    <KeycloakProvider client={authInstance}>
      <AppContent />
    </KeycloakProvider>
  )
}

export default App
